import "shared/error-logger"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import "bootstrap/dist/css/bootstrap.min.css"
import "components/react-app"
import "controllers/index"
import "stylesheets/application"


// API maker
import {default as ApiMakerConfig} from "@kaspernj/api-maker/src/config"

ApiMakerConfig.setHistory(AppHistory)


// HayaSelect
import BodyPortal from "components/layout/body-portal"
import {default as HayaSelectConfig} from "haya-select/src/config"

HayaSelectConfig.setBodyPortal(BodyPortal)
